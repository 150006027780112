body {
    overflow-y: hidden;
}

.App {
    text-align: center;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#renderCanvas {
    width: 100%;
    height: 100vh;
}

#renderType {
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 50vw;
    text-align: right;
    color: white;
}

.version {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 50vw;
    text-align: left;
    color: white;
    font-size: 8px;
}



.settings {
    position: absolute;
    top: 10px;
    right: 0px;
    text-align: left;
    background-color: #282c34;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px;
    border-style: dashed;
    opacity: 70%;
    border-color: white;
    border-right: none;
    color: white;
}

.settings button {
    width: 150px;
    padding: 3px;
}

.slidecontainer input {
    width: 100%;
} 

.postprocess-option {
    display: block;
    padding: 5px;
    margin: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.gui {
    position: absolute;
}

.buttonSN {
    font-size: 64px;
    width: -webkit-fill-available;
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-color: #282c34;
    color: white;
    border-radius: 10px;
    display: block;
    border: 1px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-style: dashed;
    opacity: 70%;
}

.buttonSN:hover {
    background-color: #494949;
    text-decoration: none;
}

.buttonSN:active {
    transform: scale(1.2);
    transition: 0.1s;
}

.buttonSN:disabled {
    background-color: #979797;
    opacity: 50%;
}

.upper {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.lower {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.middle {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
}

.dimensions, .face-popup {
    position: absolute;
    top: 1000px;
    left: 0;
    border: 1px solid #ddd;
    opacity: 70%;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s;
    background-color: #282c34;
    border-radius: 10px;
    border: 1px;
    border-style: dashed;
    opacity: 70%;
}

.dimensions h2 {
    font-size: 16px;
    margin-bottom: 10px;
}

.dimensions label {
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.dimensions input[type="number"] {
    width: 37px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dimensions button {
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px;
}


.dimensions button:hover {
    background-color: #45a049;
}

h4 {
    color: white;
    font-family: Arial, sans-serif;
    margin-left: 10px;
}

input[type="checkbox"] {
    clip-path: circle(46% at 50% 50%);
}

.icon  {
    width: 64px;
  }

.test {
 
        position: absolute;
        top: 0px;
        right: 500px;
        text-align: right;
        background-color: #282c34;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border: 1px;
        border-style: dashed;
        opacity: 70%;
        border-color: white;
        border-right: none;
        color: white;
    
    
    
  }